<template>
  <div>
    <div class="title">{{$t('breakdownTotal')}}</div>
    <div class="flex-row" style=" margin-bottom: 20px;">
      <div class="btn-list">
        <el-button
          :class="item.select?'btn-black':'btn-gray'"
          v-for="(item,index) in btnList"
          :key="index"
          @click="typeClick(item,index)"
        >{{$t(item.title)}}</el-button>
      </div>
      <el-form class="find-view flex-row" ref="form" :model="form">
        <el-form-item class="search-date" :label="$t('screeningTime')">
          <el-form-item prop="date1">
            <el-date-picker type="date" :placeholder="$t('selectDate')" v-model="form.date1"></el-date-picker>
          </el-form-item>
          <span>&nbsp;{{$t('to')}}&nbsp;</span>
          <el-form-item prop="date2">
            <el-date-picker type="date" :placeholder="$t('selectDate')" v-model="form.date2"></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-button class="btn-black">{{$t('search')}}</el-button>
      </el-form>
    </div>
    <el-table :header-cell-style="getRowClass" :data="tableData" style="width: 950px">
      <el-table-column prop="id" :label="$t('transactionNumber')" width="160" align="center"></el-table-column>
      <el-table-column prop="date" :label="$t('tradingHours')" width="150" align="center"></el-table-column>
      <el-table-column prop="name" :label="$t('transactionType')" width="150" align="center"></el-table-column>
      <el-table-column prop="price" :label="$t('transactionAmount')" width="150" align="center"></el-table-column>
      <el-table-column prop="zip" :label="$t('accountBalance')" width="170" align="center"></el-table-column>
      <el-table-column
        prop="id2"
        :label="$t('blockNumber')"
        width="170"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: ''
      },
      tableData: [
        {
          id: 'LT000326',
          id2: '6e325fcb29bec9f5146608366388712604d11b4cf452a9a0798e6e6f021b381d',
          name: `提现`,
          price: '-16.3',
          term: '3/6',
          type1: '已签约',
          type2: '156.0',
          type3: '还款中',
          date: '2020-09-04 18:19',
          zip: '1,000.0'
        }, {
          id: 'LT000343',
          id2: '8ea890dd60ce229a404b587548e90ced55b1674df52925240704560713dde1ab',
          name: `收益`,
          price: '+576.3',
          term: '1/1',
          type1: '已签约',
          type2: '380.0',
          type3: '还款中',
          date: '2020-09-04 15:14',
          zip: '1,016.3'
        }, {
          id: 'LT000366',
          id2: 'e19ee1817a798048a92b19a9f3951d348bee676c874ea353c7a68be938bb3b4e',
          name: '投资',
          price: '-560.0',
          term: '2/30',
          type1: '已签约',
          type2: '56.0',
          type3: '已完成',
          date: '2020-07-04 17:14',
          zip: '440.0'
        }, {
          id: 'LT000399',
          id2: '798048a92b19a9f395e19ee1817a1d348bee676c874ea353c7a68be938bb3b4e',
          name: '充值',
          price: '+1,000.0',
          term: '11/15',
          type1: '已签约',
          type2: '200.0',
          type3: '已完成',
          date: '2020-07-04 16:47',
          zip: '1,000.0'
        }
      ],
      btnList: [{ title: 'all', select: true }, { title: 'profit', select: false }, { title: 'investment', select: false }, { title: 'withdrawal', select: false }]
    };
  },
  onLoad() { },
  methods: {
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background:#81d8d0;color:#fff;';
      } else {
        return '';
      }
    },
    typeClick(item, index) {
      this.btnList.forEach(item => {
        item.select = false;
      });
      this.btnList[index].select = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.top-view {
  height: 90px;
  border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  line-height: 30px;
  > div {
    flex: 1;
    border-left: 1px solid #979797;
  }
  > div:nth-child(1) {
    border: none;
  }
  // > div:nth-child(2) {
  //   border-left: 1px solid #979797;
  //   border-right: 1px solid #979797;
  // }
}
.btn-gray {
  background: #dddcdb !important;
  border-color: #dddcdb !important;
  color: white !important;
}
.btn-gray:focus {
  background: #ffcc80 !important;
  border-color: #ffcc80 !important;
}
.find-view {
  margin-left: 100px;
  .search-date {
    width: 450px;
    margin-bottom: 0;
    .el-form-item,
    .el-form-item__label,
    .el-form-item__content {
      display: inline-block;
    }
    .el-input,
    .el-input__inner {
      width: 150px;
    }
  }
}
.tab .el-table {
  th {
    background: #81d8d0;
    color: white;
  }
}
</style>